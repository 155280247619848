import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

import { usersApi } from "@/api/users";

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    parent: "",
    user: {},
    oAuthToken: "",
  },
  mutations: {
    getParent(state, parent) {
      state.parent = parent;
    },
    getUser(state, user) {
      state.user = user;
    },
    getOAuthToken(state, oAuthToken) {
      state.oAuthToken = oAuthToken;
    },
  },
  actions: {
    async getParentUrl({ commit }, parent) {
      commit("getParent", parent);
    },
    async storeOauthToken({ commit }, oAuthToken) {
      commit("getOAuthToken", oAuthToken);
      if (oAuthToken != this.state.oAuthToken) alert("get the user");

      var u = await usersApi.getByToken();
      commit("getUser", u);
    },
    async storeUsername({ commit }, oAuthToken) {
      commit("getOAuthToken", oAuthToken);
    },
  },
  modules: {},
});
