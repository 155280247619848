import * as axios from "axios";
import store from "../store";

import { API } from "../config.js";

const getAll = async function() {
  const response = await axios({
    method: "get",
    url: `${API}/users`,
    headers: {
      Authorization: `Bearer ${store.state.oAuthToken}`,
    },
  });

  return response.data;
};

const getByToken = async function() {
  const response = await axios({
    method: "get",
    url: `${API}/users/getByToken`,
    headers: {
      Authorization: `Bearer ${store.state.oAuthToken}`,
    },
  });

  if (response.data == "")
  {
    localStorage.removeItem("email");
    localStorage.removeItem("pwd");
    localStorage.removeItem("token");
    window.top.location.href = "http://datumpr.bawdens.com.au";
  }

  return response.data;
};

export const usersApi = {
  getAll,
  getByToken,
};
